.selectBox div {
  height: 2.5rem !important;
}

.selectBox div span {
  display: flex !important;
  align-items: center !important;
}

.selectBox div span input {
  height: 2.5rem !important;
}
