@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat.ttf) format("truetype");
}

body {
  font-family: Montserrat !important;
  background-color: #f5f5f5;
  color: #282827;
}

.ant-menu-item {
  margin-bottom: 10px !important;
  height: 60px !important;
}

.ant-menu-item:hover {
  background-color: #fffdf0 !important;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  background-color: #e2b024 !important;
  color: white;
  text-align: center;
  font-size: 16px;
}

.ant-input-search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e2b024;
}

.ant-spin-dot-item {
  background-color: #fff !important;
}

.ant-picker-ok button {
  background-color: #e2b024 !important ;
  color: #fff !important;
  border-color: #e2b024;
}

.ant-menu-item-selected {
  border-right: 4px solid;
}

:where(.css-dev-only-do-not-override-u8obfl).ant-modal .ant-modal-content {
  box-shadow: none !important;
}

/* :where(.css-dev-only-do-not-override-u8obfl).ant-select .ant-select-arrow {
  top: 65% !important;
} */
